import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import Header from "./Header";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import Side_bar from "./Side_bar";
import { env } from "../core/service/envconfig";
import "semantic-ui-css/semantic.min.css";
import AdvertiserTable from "./AdvertiserTable";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";

const P2P = () => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [imageload, setimageload] = useState(false);

  const [Bankdetails, setBankdetails, Bankdetailsref] = useState({});
  const [Editdata, setEditdata, Editdataref] = useState({});
  const [Editstatus, setEditstatus, Editstatusref] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);

  const [formData, setFormData, formDataref] = useState({
    name: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountType: "",
    upid: "",
    QRcode: null,
  });
  const [errors, setErrors] = useState({});

  const paymentMethods = [
    {
      key: "imps",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-imps"></div>
          IMPS
        </div>
      ),
      value: "IMPS",
    },
    {
      key: "upid",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-upi"></div>
          UPID
        </div>
      ),
      value: "UPID",
    },
    {
      key: "paytm",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-paytm"></div>
          Paytm
        </div>
      ),
      value: "Paytm",
    },
    {
      key: "bankTransfer",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-bank"></div>
          Bank Transfer
        </div>
      ),
      value: "BankTransfer",
    },
  ];

  useEffect(() => {
    Getbankdetails();
  }, [0]);

  const Getbankdetails = async (e) => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.Getbankdetails,
      };

      var resp = await getMethod(data);
      console.log(resp.data, "fiat price -=-=-resp=-=-");
      setSiteLoader(false);

      if (resp.status) {
        setBankdetails(resp.data);
      } else {
        setBankdetails({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMethodChange = (e, { value }) => {
    setSelectedMethod(value);
    setFormVisible(true);
    setErrors({}); // Reset errors when method changes
  };

  const sanitizeInput = (value) => {
    return value.trim();
  };

  const handleInputChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    setFormData({ ...formData, [e.target.name]: sanitizedValue });
    validateForm(formDataref.current);
  };

  const handleFileChange = (e) => {
    try {
      setimageload(true);
      const fileExtension = e.name.split(".").at(-1);
      const fileSize = e.size;
      const fileName = e.name;
      if (
        fileExtension != "png" &&
        fileExtension != "webp" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        const data = new FormData();
        data.append("file", e);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            setFormData({ ...formData, ["QRcode"]: data.secure_url });

            validateForm(formDataref.current);
            setimageload(false);
          })
          .catch((err) => {
            console.log(err);
            setimageload(false);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      setimageload(false);
      toast.error("Please try again later");
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (selectedMethod === "IMPS" || selectedMethod === "BankTransfer") {
      if (!formData.accountNumber)
        newErrors.accountNumber = "Account Number is required";
      if (!formData.bankName) newErrors.bankName = "Bank Name is required";
      if (!formData.branch) newErrors.branch = "Branch is required";
      if (!formData.ifsc) newErrors.ifsc = "IFSC is required";
      if (!formData.accountType)
        newErrors.accountType = "Account Type is required";
    }
    if (selectedMethod === "UPID" && !formData.upid)
      newErrors.upid = "UPID is required";
    if (selectedMethod === "Paytm" && !formData.upid)
      newErrors.upid = "Paytm No is required";
    if (selectedMethod === "Paytm" && !formData.QRcode)
      newErrors.paytmQR = "QR code is required";
    if (selectedMethod === "UPID" && !formData.QRcode)
      newErrors.upidQR = "QR code is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    setSiteLoader(true);

    e.preventDefault();
    if (validateForm(formDataref.current)) {
      formData.type = selectedMethod;
      // Submit the form
      console.log("Form submitted successfully:", formData);

      var data = {
        apiUrl: apiService.addbankdetails,
        payload: formDataref.current,
      };

      var resp = await postMethod(data);
      console.log(resp.data, "fiat price -=-=-resp=-=-");
      setSiteLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        Sentback();
      } else {
        toast.error(resp.Message);
        Sentback();
      }

      Getbankdetails();
    } else {
      const error = await validateForm(formData);
      console.log("Form validation failed", error);
    }
  };

  const handleEdit = async (e) => {
    setSiteLoader(true);

    e.preventDefault();
    if (validateForm(formDataref.current)) {
      formData.type = selectedMethod;

      console.log("Form submitted successfully:", formData);

      var data = {
        apiUrl: apiService.updateBankdetails,
        payload: formDataref.current,
      };

      var resp = await postMethod(data);
      console.log(resp.data, "fiat price -=-=-resp=-=-");
      setSiteLoader(false);

      if (resp.status) {
        toast.success(resp.Message);

        Sentback();
      } else {
        toast.error(resp.Message);
        Sentback();
      }
      setEditstatus(false);
      setFormVisible(false);
      setSelectedMethod("");
      setFormData({});
      Getbankdetails();
    } else {
      const error = await validateForm(formData);
      console.log("Form validation failed", error);
    }
  };

  const Sentback = () => {
    setSelectedMethod("");
    setFormVisible(false);
    setFormData({});
    setErrors({});
  };

  const Editpayment = (data) => {
    setSelectedMethod(data.type);
    setFormData({
      name: data.Accout_HolderName,
      accountNumber: data.Account_Number,
      bankName: data.Bank_Name,
      branch: data.Branch_Name,
      ifsc: data.IFSC_code,
      accountType: data.Account_Type,
      upid: data.Upid_ID,
      QRcode: data.QRcode,
      _id: data._id,
    });

    console.log(formDataref.current, "iiknknkn");
    setEditstatus(true);
    setFormVisible(true);
  };

  const deletePayment = async (data) => {
    setSiteLoader(true);

    var data = {
      apiUrl: apiService.deletbankDetails,
      payload: { _id: data },
    };

    var resp = await postMethod(data);
    console.log(resp.data, "fiat price -=-=-resp=-=-");
    setSiteLoader(false);

    if (resp.status) {
      toast.success(resp.message);
      Getbankdetails();
    } else {
      Getbankdetails();
      toast.error(resp.message);
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container">
            <div className="row">
              {!formVisible ? (
                <div className="col-lg-12">
                  <section className="asset_section">
                    <div className="row">
                      {/* head */}
                      <div className="p2p-pay-wrap p2p-order-head">
                        <Link to="/p2p">
                          <div className="p2p-order-title text-p2p">P2P</div>
                        </Link>
                        <div className="p2p-side-arrow">
                          <i className="ri-arrow-right-s-line"></i>
                        </div>
                        <div className="p2p-order-title text-order">
                          Payments
                        </div>
                      </div>

                      {/* <div className="p2p_title align-items-center">
                        Payment
                      </div> */}

                      <div className="p2p_method_content">
                        <p>
                          When adding a payment method on Voltrix Crypt, ensure
                          the account name matches your verified name for secure
                          transactions. Your payment methods will be visible to
                          others during trades, allowing you to manage up to 20
                          options for flexibility and convenience.
                        </p>

                        <div className="pay-coin">
                          <span className="icon-container">
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          <Dropdown
                            inline
                            placeholder="Add Payment Method"
                            options={paymentMethods}
                            value={selectedMethod}
                            onChange={handleMethodChange}
                            className="pay-coin payment"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="mt-5">
                          {Array.isArray(Bankdetails) &&
                          Bankdetails.length > 0 ? (
                            Bankdetails.map((options, index) => (
                              <div className="payments mt-5" key={index}>
                                <div className="payment-head">
                                  <div className="d-flex align-items-center">
                                    {" "}
                                    {options.type === "IMPS" && (
                                      <div className="pay-bor bg-imps"></div>
                                    )}
                                    {options.type === "UPID" && (
                                      <div className="pay-bor bg-upi"></div>
                                    )}
                                    {options.type === "Paytm" && (
                                      <div className="pay-bor bg-paytm"></div>
                                    )}
                                    {options.type === "BankTransfer" && (
                                      <div className="pay-bor bg-bank"></div>
                                    )}
                                    {options.type}
                                  </div>
                                  <div>
                                    <span onClick={() => Editpayment(options)}>
                                      Edit
                                    </span>
                                    <span
                                      onClick={() => deletePayment(options._id)}
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>

                                <div className="payment-table row">
                                  <div className="col-lg-4">
                                    <div className="label">Full Name</div>
                                    <div className="content">
                                      {options.Accout_HolderName || "N/A"}
                                    </div>
                                  </div>

                                  {options.type == "UPID" ||
                                  options.type == "Paytm" ? (
                                    <>
                                      <div className="col-lg-4">
                                        <div className="label">Account No</div>
                                        <div className="content">
                                          {options.Upid_ID || "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-lg-4">
                                        <div className="label">QR CODE</div>
                                        <div className="content">
                                          <img
                                            src={options.QRcode}
                                            alt="QR Code"
                                            width="100px"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {options.type == "IMPS" ||
                                  options.type == "BankTransfer" ? (
                                    <>
                                      <div className="col-lg-4">
                                        <div className="label">Account No</div>
                                        <div className="content">
                                          {options.Account_Number || "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-lg-4">
                                        <div className="label">IFSC Code</div>
                                        <div className="content">
                                          {options.IFSC_code || "N/A"}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="not-pay-wrapper">
                              {/* No bank details available. */}
                              <img
                                src={require("../assets/not-add-pay.png")}
                                alt="not-pay"
                                className="not-pay"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : (
                <>
                  <div className="col-lg-12">
                    <section className="asset_section">
                      <div className="row mt-5 justify-content-center">
                        <div>
                          <Link onClick={Sentback} className="">
                            <h6 className="payment-back">
                              {" "}
                              <i class="fa-solid fa-arrow-left-long mr-4"></i>{" "}
                              <span>Back</span>
                            </h6>
                          </Link>
                        </div>

                        <div className="row justify-content-center pay-cards mt-4">
                          <div className="col-lg-7 ">
                            <form className="p2p-payment-form">
                              <div className="p2p_payment">
                                {/* border-left colors */}
                                {selectedMethod === "IMPS" && (
                                  <div className="pay-bor bg-imps"></div>
                                )}
                                {selectedMethod === "UPID" && (
                                  <div className="pay-bor bg-upi"></div>
                                )}
                                {selectedMethod === "Paytm" && (
                                  <div className="pay-bor bg-paytm"></div>
                                )}
                                {selectedMethod === "BankTransfer" && (
                                  <div className="pay-bor bg-bank"></div>
                                )}

                                {/* select method */}
                                {selectedMethod}
                              </div>

                              <div className="pay-tips">
                                <span>
                                  <img
                                    src={require("../assets/deposit-imp.png")}
                                    alt="tips-icon"
                                    className="pay-tips-icon"
                                  />
                                </span>

                                <div>
                                  <span className="text-yellow">Tips:</span>

                                  {selectedMethod === "IMPS" && (
                                    <span className="pay-tips-content">
                                      Enter your account details accurately and
                                      ensure they match your verified name for
                                      secure transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "UPID" && (
                                    <span className="pay-tips-content">
                                      Enter your UPI ID accurately and ensure it
                                      matches your verified name for secure
                                      transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "Paytm" && (
                                    <span className="pay-tips-content">
                                      Enter your UPI ID accurately and ensure it
                                      matches your verified name for secure
                                      transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "BankTransfer" && (
                                    <span className="pay-tips-content">
                                      Enter your bank account details accurately
                                      and ensure they match your verified name
                                      for secure transactions.
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="input-groups">
                                <h6 className="input-label text-white">Name</h6>
                                <input
                                  type="text"
                                  name="name"
                                  className="payment-input"
                                  placeholder="Please enter your name"
                                  maxLength="30"
                                  value={formData.name || ""}
                                  onChange={handleInputChange}
                                />
                                {errors.name && (
                                  <span className="errorcss">
                                    {errors.name}
                                  </span>
                                )}
                              </div>

                              {selectedMethod === "IMPS" ||
                              selectedMethod === "BankTransfer" ? (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Bank Account/Card Number
                                    </h6>
                                    <input
                                      type="Number"
                                      name="accountNumber"
                                      onChange={(e) => {
                                        // Allow only numbers and limit the length to 10 digits
                                        const value = e.target.value;
                                        if (
                                          value.length <= 30 &&
                                          /^[0-9]*$/.test(value)
                                        ) {
                                          handleInputChange(e);
                                        }
                                      }}
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      className="payment-input"
                                      placeholder="Please enter your bank account/card number"
                                      value={formData.accountNumber || ""}
                                      // onChange={handleInputChange}
                                    />
                                    {errors.accountNumber && (
                                      <span className="errorcss">
                                        {errors.accountNumber}
                                      </span>
                                    )}
                                  </div>

                                  {/* ifsc */}
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      IFSC Code
                                    </h6>
                                    <input
                                      type="text"
                                      maxLength="30"
                                      name="ifsc"
                                      className="payment-input"
                                      placeholder="Please enter your IFSC code"
                                      value={formData.ifsc || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.ifsc && (
                                      <span className="errorcss">
                                        {errors.ifsc}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Bank Name
                                    </h6>
                                    <input
                                      type="text"
                                      name="bankName"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Please enter your bank name"
                                      value={formData.bankName || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.bankName && (
                                      <span className="errorcss">
                                        {errors.bankName}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Account Type
                                    </h6>
                                    <input
                                      type="text"
                                      name="accountType"
                                      maxLength="10"
                                      className="payment-input"
                                      placeholder="Specify the account type (savings or current)"
                                      value={formData.accountType || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.accountType && (
                                      <span className="errorcss">
                                        {errors.accountType}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Account Branch
                                    </h6>
                                    <input
                                      type="text"
                                      name="branch"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Please enter your account branch"
                                      value={formData.branch || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.branch && (
                                      <span className="errorcss">
                                        {errors.branch}
                                      </span>
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {selectedMethod === "UPID" && (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      UPI ID
                                    </h6>
                                    <input
                                      type="text"
                                      name="upid"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Please enter your UPI ID"
                                      value={formData.upid || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.upid && (
                                      <span className="errorcss">
                                        {errors.upid}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Payment QR Code (Optional)
                                    </h6>

                                    <div className="file-input-wrapper">
                                      <input
                                        id="file-input"
                                        type="file"
                                        name="upidQR"
                                        className="payment-input bg-transparent"
                                        onChange={(e) =>
                                          handleFileChange(e.target.files[0])
                                        }
                                      />
                                      <label
                                        htmlFor="file-input"
                                        className="file-input-label"
                                      >
                                        {imageload ? (
                                          <i class="fa-solid fa-spinner fa-spin"></i>
                                        ) : (
                                          <i class="fa-solid fa-arrow-up-from-bracket"></i>
                                        )}
                                      </label>
                                    </div>

                                    {errors.upidQR && (
                                      <span className="errorcss">
                                        {errors.upidQR}
                                      </span>
                                    )}

                                    {formData.QRcode ? (
                                      <img
                                        src={formData.QRcode}
                                        width="100px"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}

                              {selectedMethod === "Paytm" && (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      {" "}
                                      Paytm No
                                    </h6>
                                    <input
                                      type="text"
                                      name="upid"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Enter UPID"
                                      value={formData.upid || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.upid && (
                                      <span className="errorcss">
                                        {errors.upid}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Paytm QR Image (Optional)
                                    </h6>
                                    <div className="file-input-wrapper">
                                      <input
                                        id="file-input-paytm"
                                        type="file"
                                        name="paytmQR"
                                        className="payment-input bg-transparent"
                                        onChange={(e) =>
                                          handleFileChange(e.target.files[0])
                                        }
                                      />
                                      <label
                                        htmlFor="file-input-paytm"
                                        className="file-input-label"
                                      >
                                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                                      </label>
                                    </div>

                                    {errors.paytmQR && (
                                      <span className="errorcss">
                                        {errors.paytmQR}
                                      </span>
                                    )}
                                  </div>
                                </>
                              )}

                              <div className="ad-upload">
                                <div className="Submit mt-4">
                                  {Editstatus == false ? (
                                    <button
                                      type="submit"
                                      onClick={handleSubmit}
                                    >
                                      Add Payment Method
                                    </button>
                                  ) : (
                                    <button type="submit" onClick={handleEdit}>
                                      Edit Payment Method
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default P2P;
