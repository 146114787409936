import "./App.css";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./Pages/Landing";
import Register from "./Pages/Register";
import Verification from "./Pages/Verification";
import Login from "./Pages/Login";
import TFA from "./Pages/TFA";
import FP from "./Pages/FP";
import Phising from "./Pages/Phising";
import Trade from "./Pages/Trade";
import Changepassword from "./Pages/Changepassword";
import EnableTFA from "./Pages/EnableTFA";
import Dashboard from "./Pages/Dashboard";
import Staking from "./Pages/Staking";
import Swap from "./Pages/Swap";
import Security from "./Pages/Security";
import Anti_phishing from "./Pages/Anti_phishing";
import Security_change from "./Pages/Security_change";
import Security_2fa from "./Pages/Security_2fa";
import Kyc from "./Pages/Kyc";
import Buycrypto from "./Pages/Buycrypto";
import Assets from "./Pages/Assets";
import DepositHistory from "./Pages/DepositHistory";
import Quick_buy from "./Pages/Quick_buy";
import Deposit from "./Pages/Deposit";

import Refferal from "./Pages/Refferal";

import Vieworder from "./Pages/view-order";

import WithdrawTable from "./Pages/WithdrawTable";
import TradeTable from "./Pages/TradeTable";
import LoginTable from "./Pages/LoginTable";
import ReferralTable from "./Pages/ReferralTable";
import StakingTable from "./Pages/StakingTable";
import OrderHisTable from "./Pages/OrderHisTable";
import Rewards from "./Pages/Rewards";
import Market from "./Pages/Market";
import Settings from "./Pages/Settings";
import Withdrawal from "./Pages/Withdrawal";
import Support from "./Pages/Support";
import InternalTransfer from "./Pages/InternalTransfer";
import Bankdetails from "./Pages/Bankdetails";

import P2P from "./Pages/P2P";
import P2PConvert from "./Pages/P2PConvert";
import Payment from "./Pages/Payment";
import P2PSell from "./Pages/P2PSell";
import PostAd from "./Pages/PostAd";
import FiatDeposit from "./Pages/FiatDeposit";
import Fiat from "./Pages/Fiat";
import { AuthProvider } from "././Pages/AuthContext";
import { removeAuthToken } from "../src/core/lib/localStorage";
import { useEffect } from "react";
import ProcessOrderTable from "./Pages/ProcessOrderTable";
import MyOrdersTable from "./Pages/MyOrdersTable";
import MyHistoryTable from "./Pages/MyHistoryTable";
import SwapHistoryTable from "./Pages/SwapHistoryTable";
import { getMethod } from "./core/service/common.api";
import apiService from "./core/service/detail";
import useState from "react-usestateref";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Airdrop from "./Pages/Airdrop";
import Checkout from "./Pages/FiatChceckout";


import AirdropTokens from "./Pages/AirdropTokens";
import RefundPolicy from "./Pages/RefundPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy";
import AirdropGame from "./Pages/AirdropGame";

function App() {
  const [favIconSite, setFaviconSite, favIconSiteref] = useState("");
  useEffect(() => {
    if (window.AOS) {
      window.AOS.init({
        duration: 1000, // Animation duration
        easing: "ease-in-out", // Easing function
        once: true, // Only animate once
      });
    }
    favIcon();
  }, []);

  const favIcon = async () => {
    try {
      const data = { apiUrl: apiService.getSitedata };
      const resp = await getMethod(data);
      if (resp.status === true) {
        setFaviconSite(resp.data.favicon); // Store favicon URL
        setFavicon(resp.data.favicon); // Dynamically set favicon
      }
    } catch (error) {
      console.error("Failed to fetch favicon:", error);
    }
  };

  const setFavicon = (url) => {
    console.log("Setting favicon with URL:", url);
    // Remove any existing favicon element
    const existingFavicon = document.getElementById("favicon");
    if (existingFavicon) {
      existingFavicon.remove();
    }

    // Create new link element for favicon
    const link = document.createElement("link");
    link.id = "favicon";
    link.rel = "icon";
    link.href = url;
    document.head.appendChild(link);
  };

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/register" element={<Register />} />
            <Route path="/trade/:pair" element={<Trade />} />

            <Route path="/p2p/order/:id" element={<Vieworder />} />

            <Route path="/verification" element={<Verification />} />
            <Route path="/login" element={<Login />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refundpolicy" element={<RefundPolicy />} />
            <Route path="/returnpolicy" element={<ReturnPolicy />} />
            <Route path="/tfa" element={<TFA />} />
            <Route path="/forgotpassword" element={<FP />} />
            {/* <Route path="/antiphishing" element={<Phising />} /> */}
            <Route
              path="/antiphishing"
              element={
                <RequireAuth>
                  <Phising />
                </RequireAuth>
              }
            />
            <Route
              path="/changepassword"
              element={
                <RequireAuth>
                  <Changepassword />
                </RequireAuth>
              }
            />
            <Route
              path="/enabletfa"
              element={
                <RequireAuth>
                  {" "}
                  <EnableTFA />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  {" "}
                  <Dashboard />{" "}
                </RequireAuth>
              }
            />
            <Route path="/staking" element={<Staking />} />
            <Route
              path="/refferal"
              element={
                <RequireAuth>
                  {" "}
                  <Refferal />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/swap"
              element={
                <RequireAuth>
                  {" "}
                  <Swap />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/security"
              element={
                <RequireAuth>
                  {" "}
                  <Security />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/anti-phishing"
              element={
                <RequireAuth>
                  {" "}
                  <Anti_phishing />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/security_change"
              element={
                <RequireAuth>
                  {" "}
                  <Security_change />{" "}
                </RequireAuth>
              }
            />
            {/* <Route path="/security_2fa" element={<Security_2fa />} /> */}
            <Route path="/kyc" element={<Kyc />} />
            {/* <Route path="/buycrypto" element={<Buycrypto />} /> */}
            <Route
              path="/assets"
              element={
                <RequireAuth>
                  {" "}
                  <Assets />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/depositHistory"
              element={
                <RequireAuth>
                  {" "}
                  <DepositHistory />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/withdrawHistory"
              element={
                <RequireAuth>
                  {" "}
                  <WithdrawTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/tradeHistory"
              element={
                <RequireAuth>
                  <TradeTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/loginHistory"
              element={
                <RequireAuth>
                  {" "}
                  <LoginTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/swapHistory"
              element={
                <RequireAuth>
                  {" "}
                  <SwapHistoryTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/referralHistory"
              element={
                <RequireAuth>
                  {" "}
                  <ReferralTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/stakingHistory"
              element={
                <RequireAuth>
                  <StakingTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/orderHistory"
              element={
                <RequireAuth>
                  <OrderHisTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/rewards"
              element={
                <RequireAuth>
                  <Rewards />{" "}
                </RequireAuth>
              }
            />
            <Route path="/market" element={<Market />} />
            {/* <Route
              path="/settings"
              element={
                <RequireAuth>
                  {" "}
                  <Settings />{" "}
                </RequireAuth>
              }
            /> */}
            <Route
              path="/withdraw"
              element={
                <RequireAuth>
                  {" "}
                  <Withdrawal />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/deposit"
              element={
                <RequireAuth>
                  {" "}
                  <Deposit />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/support"
              element={
                <RequireAuth>
                  <Support />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/internaltransfer"
              element={
                <RequireAuth>
                  {" "}
                  <InternalTransfer />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/p2p"
              element={
                <RequireAuth>
                  {" "}
                  <P2P />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/processorders"
              element={
                <RequireAuth>
                  {" "}
                  <ProcessOrderTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/myorders"
              element={
                <RequireAuth>
                  {" "}
                  <MyOrdersTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/myhistory"
              element={
                <RequireAuth>
                  {" "}
                  <MyHistoryTable />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/p2pconvert"
              element={
                <RequireAuth>
                  {" "}
                  <P2PConvert />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/p2p/chat/:id"
              element={
                <RequireAuth>
                  {" "}
                  <Payment />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/sell"
              element={
                <RequireAuth>
                  {" "}
                  <P2PSell />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/postad"
              element={
                <RequireAuth>
                  {" "}
                  <PostAd />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/fiat"
              element={
                <RequireAuth>
                  {" "}
                  <Fiat />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/fiatdeposit"
              element={
                <RequireAuth>
                  {" "}
                  <FiatDeposit />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/Paymentmethod"
              element={
                <RequireAuth>
                  <Bankdetails />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/airdrop"
              element={
                <RequireAuth>
                  <Airdrop />{" "}
                </RequireAuth>
              }
            />
                <Route
                  path="/checkout"
                  element={
                  <RequireAuth>
                  <Checkout />{" "}
                  </RequireAuth>
                  }
                />
            <Route path="/airdroptokens" element={<AirdropTokens />} />
            <Route path="/airdropgame" element={<AirdropGame />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
