import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "semantic-ui-css/semantic.min.css";

const P2P = () => {
  const navigate = useNavigate();
  const [p2pOrders, setP2POrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectPayment, setselectPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [orderType, setOrderType] = useState("buy");
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [selectedFiat, setSelectedFiat] = useState("");
  const [profileData, setProfileData, profileDataRef] = useState("");
  const [isBuy, setIsBuy] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [isIndexVal, setIsIndexVal] = useState("");
  const [UserID, setUserID, UserIDref] = useState("");

  const preferPayment = [
    { value: "All Payment", text: "All Payment" },
    { key: "imps", text: "IMPS", value: "IMPS" },
    { key: "upid", text: "UPID", value: "UPID" },
    { key: "paytm", text: "Paytm", value: "Paytm" },
    { key: "bankTransfer", text: "Bank Transfer", value: "BankTransfer" },
  ];

  const allpayment = [
    { key: "imps", text: "IMPS", value: "IMPS" },
    { key: "upid", text: "UPID", value: "UPID" },
    { key: "paytm", text: "Paytm", value: "Paytm" },
    { key: "bankTransfer", text: "Bank Transfer", value: "BankTransfer" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("VTXToken");
    const VTX = token.split("_")[1];
    setUserID(VTX);

    console.log(UserIDref.current, "setUserID");

    getAllP2POrders();
    getAllCurrency();
    getProfile();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [
    paymentMethod,
    orderType,
    selectedCrypto,
    selectedFiat,
    p2pOrders,
    amount,
  ]);

  const getAllP2POrders = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.p2pGetOrder,
        payload: {
          currency: selectedCrypto || selectedFiat,
        },
      };
      const resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setP2POrders(resp.Message);
        setFilteredOrders(resp.Message);

        console.log(resp, "resp");
      }
    } catch (error) {
      console.error("Error fetching P2P orders:", error);
    }
  };

  const getAllCurrency = async () => {
    setSiteLoader(true);

    try {
      const data = { apiUrl: apiService.getP2Pcurrency };
      const resp = await getMethod(data);
      setSiteLoader(false);
      if (resp && resp.data) {
        const cryptoArray = resp.data
          .filter((currency) => currency.coinType === "1")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        const fiatArray = resp.data
          .filter((currency) => currency.coinType === "2")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        setCryptoCurrencies(cryptoArray);
        setFiatCurrencies(fiatArray);
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const getProfile = async () => {
    try {
      setSiteLoader(true);

      const data = { apiUrl: apiService.getUserDetails };
      const resp = await getMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        setProfileData(resp.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const filterOrders = () => {
    setSiteLoader(true);

    let filtered = p2pOrders;

    if (paymentMethod) {
      filtered = filtered.filter(
        (order) => order.paymentMethod === paymentMethod
      );
    }

    if (orderType) {
      filtered = filtered.filter((order) => order.orderType != orderType);
    }

    if (selectedCrypto) {
      filtered = filtered.filter(
        (order) => order.firstCurrency === selectedCrypto
      );
    }

    if (selectedFiat) {
      console.log(selectedFiat, filtered);
      filtered = filtered.filter(
        (order) => order.secondCurrency == selectedFiat
      );
    }

    if (amount) {
      filtered = filtered.filter((order) => order.price === amount);
    }

    setFilteredOrders(filtered);
    setSiteLoader(false);
  };

  const handleClick = (i, option) => {
    console.log(i, option);
    setIsBuy(option);
    setIsIndexVal(i);
  };

  const [payAmount, setPayAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");

  const handlePayAmountChange = (e) => {
    const value = e.target.value;
    // Convert to a number for comparison
    const numericValue = parseFloat(value);

    // Validate the amount entered by the user
    if (
      !isNaN(numericValue) &&
      numericValue >= isBuy.fromLimit &&
      numericValue <= isBuy.toLimit
    ) {
      setPayAmount(value);
      // Calculate the receive amount (payAmount * price)
      const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
      setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    } else {
      toast.error("Enter the valid quantity");
      setPayAmount(value);
      // Calculate the receive amount (payAmount * price)
      const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
      setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    }
  };

  const handleCancel = () => {
    setPayAmount("");
    setReceiveAmount("");
    setIsIndexVal("");
  };

  const confirm_order_buy = async () => {
    console.log("Starting order confirmation...");
    try {
      setSiteLoader(true);

      if (payAmount === "" || receiveAmount === "") {
        toast.error("Please enter valid quantity");
        setSiteLoader(false);
        return;
      }

      const commonObj = {
        qty: payAmount,
        total: receiveAmount,
        orderId: isBuy.orderId,
        type: "buy",
      };

      const paymentMethod =
        orderType === "buy" && selectPayment !== ""
          ? selectPayment
          : "All Payment";

      if (orderType === "buy" && selectPayment === "") {
        toast.error("Please select the payment methods");
        setSiteLoader(false);
        return;
      }

      const obj = { ...commonObj, paymentMethod };
      console.log(obj, "Order data");

      const data = {
        apiUrl: apiService.p2p_confirm_order,
        payload: obj,
      };

      const resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        toast.success(resp.Message);
        navigate(resp.link);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setSiteLoader(false);
      console.log(error, "Error during order confirmation");
    }
  };

  const confirm_order_sell = async () => {
    try {
      setSiteLoader(true);

      var obj = {};
      obj.qty = payAmount;
      obj.paymentMethod = selectPayment;

      obj.total = receiveAmount;
      obj.orderId = isBuy.orderId;
      obj.type = "sell";

      if (obj.qty != "" && obj.total != "") {
        if (selectPayment != "") {
          var data = {
            apiUrl: apiService.p2p_confirm_sellorder,
            payload: obj,
          };
          var resp = await postMethod(data);
          setSiteLoader(false);

          if (resp.status) {
            toast.success(resp.Message);
            navigate(resp.link);
            window.location.href = resp.link;
          } else {
            toast.error(resp.Message);
          }
        } else {
          toast.error("Please select the payment methods");
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) {}
  };

  return (
    <>
      <Header />

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <section className="asset_section">
                  <div className="row">
                    <div className="p2p_title">
                      P2P{" "}
                      <div className="p2p-head-right">
                        <span className="p2p-right-links">
                          <img
                            src={require("../assets/icons/p2p-orders.webp")}
                            alt="p2p-order"
                            className="p2p-right-icons"
                          />
                          <Link to="/processorders" className="p2p-right-links">
                            Orders
                          </Link>
                        </span>
                        <span className="p2p-right-links">
                          <img
                            src={require("../assets/icons/p2p-plus.png")}
                            alt="p2p-order"
                            className="p2p-right-icons"
                          />
                          <Link to="/postad" className="p2p-right-links">
                            Post ads
                          </Link>
                        </span>
                        <span className="p2p-right-links">
                          <img
                            src={require("../assets/icons/p2p-payment.webp")}
                            alt="p2p-payment"
                            className="p2p-right-icons"
                          />
                          <Link to="/Paymentmethod" className="p2p-right-links">
                            Payment Method
                          </Link>
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="filter-btns-wrapper mb-5">
                        <div className="fil-buy-sell">
                          <span
                            className={`fil-sell ${
                              orderType === "buy" ? "fil-buy" : ""
                            }`}
                            onClick={() => setOrderType("buy")}
                          >
                            Buy
                          </span>
                          <span
                            className={`fil-sell ${
                              orderType === "sell" ? "fil-sell-red" : ""
                            }`}
                            onClick={() => setOrderType("sell")}
                          >
                            Sell
                          </span>
                        </div>

                        <div className="fil-country">
                          <Dropdown
                            placeholder="Crypto "
                            fluid
                            selection
                            options={cryptoCurrencies}
                            onChange={(e, { value }) =>
                              setSelectedCrypto(value)
                            }
                          />
                        </div>

                        <div className="fil-country">
                          <Dropdown
                            placeholder="Fiat "
                            fluid
                            selection
                            options={fiatCurrencies}
                            onChange={(e, { value }) => setSelectedFiat(value)}
                          />
                        </div>

                        <div className="fil-payment">
                          <Dropdown
                            placeholder="All payment method"
                            fluid
                            selection
                            options={preferPayment}
                            onChange={(e, { value }) => setPaymentMethod(value)}
                            value={paymentMethod}
                          />
                        </div>

                        <div className="fil-enter">
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            className="fil-amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                          <span className="fil-inr">
                            {selectedFiat ? selectedFiat : "INR"}
                          </span>
                          <span className="white-das">|</span>
                          <span className="fil-search">Search</span>
                        </div>
                      </div>

                      <div className="table-responsive table-cont">
                        <table className="table">
                          <thead>
                            <tr className="stake-head">
                              <th>Advertiser</th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Price
                              </th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Available / Limits
                              </th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                Payment Method
                              </th>
                              <th className="opt-btn-flex table-action">
                                Trade
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {filteredOrders.map((options, i) => (
                              <React.Fragment key={options.id}>
                                {i === isIndexVal ? (
                                  <tr>
                                    <td colSpan="5">
                                      <div className="row bord-top">
                                        <div className="col-lg-7 pad-all-3">
                                          <div className="table-flex">
                                            <img
                                              src={require(`../assets/j.png`)}
                                              alt={options.optName}
                                            />
                                            <div className="table-opt-name">
                                              <h4 className="opt-nowrap opt-name font_14">
                                                {options.displayname}
                                              </h4>
                                              <h3 className="opt-nowrap opt-sub font_14">
                                                {`${options.orders_count} Volume | ${options.rating} % Transaction rate`}
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="ad-buy-details">
                                            <div className="opt-nowrap opt-term font_14 ">
                                              {options.price}{" "}
                                              {options?.secondCurrency}
                                              <div className="opt-price-span mar-t-price">
                                                Price
                                              </div>
                                            </div>
                                            <div className="opt-nowrap opt-term font_14 ">
                                              <span className="opt-pay">
                                                {options.paymentMethod}{" "}
                                              </span>
                                              <div className="opt-price-span mar-t-price">
                                                Payment Method
                                              </div>
                                            </div>
                                            <div className="opt-nowrap opt-term font_14">
                                              {options && options.fromLimit} -{" "}
                                              {options && options.toLimit}{" "}
                                              {options && options.firstCurrency}
                                              <div className="opt-price-span mar-t-price">
                                                Limit
                                              </div>
                                            </div>
                                            <div className="opt-nowrap opt-term font_14 ">
                                              15 min
                                              <div className="opt-price-span mar-t-price">
                                                Payment Time Limit
                                              </div>
                                            </div>
                                            <div className="opt-nowrap opt-term font_14 ">
                                              {options.available_qty}{" "}
                                              {options.firstCurrency}
                                              <div className="opt-price-span mar-t-price">
                                                Available
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-5 youpay">
                                          <form className="youpay-form">
                                            <label htmlFor="quantity-sell">
                                              Enter quantity to{" "}
                                              {orderType == "buy"
                                                ? "Buy"
                                                : "Sell"}
                                            </label>
                                            <div className="p2p-pay-input">
                                              <input
                                                id="quantity-sell"
                                                type="text"
                                                placeholder="Enter Amount"
                                                className="w-100 pay-input"
                                                value={payAmount}
                                                onChange={(e) => {
                                                  // Allow only numbers and limit the length to 10 digits
                                                  const value = e.target.value;
                                                  if (
                                                    value.length <= 30 &&
                                                    /^[0-9]*$/.test(value)
                                                  ) {
                                                    handlePayAmountChange(e);
                                                  }
                                                }}
                                                onKeyDown={(evt) =>
                                                  ["e", "E", "+", "-"].includes(
                                                    evt.key
                                                  ) && evt.preventDefault()
                                                }
                                              />
                                              <span className="youpay-span">
                                                {options.firstCurrency}
                                              </span>
                                            </div>
                                            <label htmlFor="you-pay">
                                              You will pay
                                            </label>
                                            <div className="p2p-pay-input">
                                              <input
                                                type="text"
                                                placeholder="0.00"
                                                className="w-100 receive-input"
                                                value={receiveAmount}
                                                readOnly
                                              />
                                              <span>
                                                {options.secondCurrnecy}
                                              </span>
                                            </div>

                                            {orderType == "buy" ? (
                                              <>
                                                <label htmlFor="you-pay">
                                                  Select Payment Method
                                                </label>

                                                <div className=" mb-4">
                                                  {options.paymentMethod ==
                                                  "All Payment" ? (
                                                    <Dropdown
                                                      placeholder="Choose payment method"
                                                      className="you-pay-select"
                                                      fluid
                                                      selection
                                                      options={allpayment}
                                                      onChange={(
                                                        e,
                                                        { value }
                                                      ) =>
                                                        setselectPayment(value)
                                                      }
                                                      value={selectPayment}
                                                    />
                                                  ) : (
                                                    <Dropdown
                                                      placeholder="Choose payment method"
                                                      className="you-pay-select"
                                                      fluid
                                                      selection
                                                      options={[
                                                        {
                                                          value:
                                                            options.paymentMethod,
                                                          text: options.paymentMethod,
                                                        },
                                                      ]}
                                                      onChange={(
                                                        e,
                                                        { value }
                                                      ) =>
                                                        setselectPayment(value)
                                                      }
                                                      value={selectPayment}
                                                    />
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            <div className="youpay-btns">
                                              <button
                                                type="button"
                                                className="youpay-cancel"
                                                onClick={handleCancel}
                                              >
                                                Cancel
                                              </button>
                                              {orderType == "buy" ? (
                                                <Link
                                                  type="submit"
                                                  onClick={() =>
                                                    confirm_order_buy()
                                                  }
                                                  className={`${
                                                    orderType == "buy"
                                                      ? "fil-buy"
                                                      : "action_btn_sell"
                                                  } `}
                                                >
                                                  Buy
                                                </Link>
                                              ) : (
                                                <Link
                                                  type="submit"
                                                  onClick={() =>
                                                    confirm_order_sell()
                                                  }
                                                  className={`${
                                                    orderType == "buy"
                                                      ? "fil-buy"
                                                      : "youpay-sell"
                                                  } `}
                                                >
                                                  <span className="mx-1">
                                                    Sell
                                                  </span>
                                                  {options.firstCurrency}
                                                </Link>
                                              )}
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={options.id}>
                                    <td>
                                      <div className="table-flex">
                                        <img
                                          src={require(`../assets/j.png`)}
                                          alt={options.optName}
                                        />
                                        <div className="table-opt-name">
                                          <h4 className="opt-nowrap opt-name font_14">
                                            {options.displayname}
                                          </h4>
                                          <h3 className="opt-nowrap opt-sub font_14">
                                            {`${options.orders_count} Volume | ${options.rating} % Transaction rate`}
                                          </h3>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="opt-nowrap opt-price font_14 table_center_text pad-left-23">
                                      {options.price}{" "}
                                      <span className="opt-price-span">
                                        {options?.secondCurrency}
                                      </span>
                                    </td>

                                    <td className="opt-nowrap opt-percent font_14 table_center_text pad-left-23">
                                      <div className="table-opt-name table-flex-col">
                                        <h4 className="opt-name font_14">
                                          <span className="opt-sub opt-sub-amt">
                                            Amount{" "}
                                          </span>
                                          <span className="opt-amount">
                                            {options && options.available_qty}{" "}
                                            {options && options.firstCurrency}
                                          </span>
                                        </h4>
                                        <h3 className="opt-sub font_14">
                                          <span className="opt-sub opt-sub-lmt">
                                            Limit{" "}
                                          </span>
                                          <span className="opt-amount">
                                            {" "}
                                            {options &&
                                              options.fromLimit} -{" "}
                                            {options && options.toLimit}{" "}
                                            {options && options.firstCurrency}
                                          </span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="opt-nowrap opt-term font_14 table_center_text pad-left-23">
                                      <span className="opt-pay">
                                        {options && options.paymentMethod}{" "}
                                      </span>
                                    </td>

                                    <td className="opt-btn-flex table-action pad-left-23">
                                      {options.user_id == UserIDref.current ? (
                                        <Link
                                          className={`${
                                            orderType == "buy"
                                              ? "p2p-buy"
                                              : "action_btn_sell"
                                          } `}
                                          to={`/p2p/order/${options.orderId}`}
                                        >
                                          View
                                        </Link>
                                      ) : (
                                        <Link
                                          className={`${
                                            orderType == "buy"
                                              ? "p2p-buy"
                                              : "action_btn_sell"
                                          } `}
                                          onClick={() =>
                                            handleClick(i, options)
                                          }
                                        >
                                          {orderType == "buy" ? "Buy" : "Sell"}
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default P2P;
