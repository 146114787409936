import React, { useEffect, useMemo } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";
import WARNICON from "../assets/icons/withdraw-warn.webp";

const colourStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "#24252a" : "#17171a",
    color: isFocused ? "#ffc630" : "#fff",
    cursor: isDisabled ? "not-allowed" : "pointer",
    borderBottom: `1px solid ${isFocused ? "#ffc630" : "#17171a"}`, // Border between options
  }),
  menu: (styles) => ({
    ...styles,
    border: "1px solid #444", // Border around the entire options menu
    borderRadius: "4px", // Optional: Add border radius
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optional: Add shadow for better visual separation
    backgroundColor: "transparent",
  }),
};

const Dashboard = () => {
  const [levelFirst, setLevelFirst, levelFirstref] = useState(true);
  const [levelScndstart, setLevelScndstart, levelScndstartref] =
    useState(false);
  const [levelThird, setLevelThird, levelThirdref] = useState(false);
  const [levelFourth, setLevelFourth, levelFourthref] = useState(false);

  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);
  const [idproof, setidproof, idproofref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);
  const [validSelfieProof, setvalidSelfieProof] = useState(0);
  const [SelfieProofup, setSelfieProof, SelfieProofref] = useState("");
  const [Selfieproofname, setSelfieproofname, Selfieproofnameref] =
    useState("");
  const [SelfieProofLoad, setSelfieProofLoad] = useState(false);
  const [uploadError, setUploadError, uploadErrorref] = useState(false);
  const [selfieError, setSelfieError, selfieErrorref] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");
  const [getKYCDataReject, setgetKYCDataReject] = useState("");

  const initialFormValue = {
    fullname: "",
    dob: "",
    nationality: "",
    residential: "",
    verfiType: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const { fullname, dob, nationality, residential, verfiType } = formValue;

  const [fullNameValidate, setfullNameValidate, fullNameValidateref] =
    useState(false);
  const [DOBValidate, setDOBValidate, DOBValidateref] = useState(false);
  const [nationalityValidate, setnationalityValidate, nationalityValidateref] =
    useState(false);
  const [addressValidate, setaddressValidate, addressValidateref] =
    useState(false);
  const [
    documentTypeValidate,
    setdocumentTypeValidate,
    documentTypeValidateref,
  ] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState("");
  const [siteStatus, setSiteStatus] = useState("");
  const [kycStatus, setkycStatus] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handleTextChange = async (e) => {
    let addrressData = { ...formValue, residential: e.target.value };
    setFormValue(addrressData);
    validate(addrressData);
  };

  const handleNationalityChange = (selectedNationality) => {
    const updatedFormValue = {
      ...formValue,
      nationality: selectedNationality.label,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleDocumentTypeChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      verfiType: event.target.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const validate = (values) => {
    let errors = {};

    if (levelFirstref.current == true) {
      if (!values.fullname) {
        errors.fullname = "FullName is a required !";
        setfullNameValidate(true);
      } else if (!/^[a-zA-Z]/.test(values.fullname)) {
        errors.fullname = "FullName must start with a letter !";
        setfullNameValidate(true);
      } else if (values.fullname.length < 3 || values.fullname.length > 25) {
        errors.fullname = "FullName must have an  3 to 25 characters !";
        setfullNameValidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(values.fullname)) {
        errors.fullname =
          "FullName can only contain letters, numbers, and underscores !";
        setfullNameValidate(true);
      } else {
        setfullNameValidate(false);
      }
      if (values.dob == "") {
        setDOBValidate(true);
        errors.dob = "Date of birth is required !";
      } else if (values.dob > today) {
        setDOBValidate(true);
        errors.dob = "Please enter a correct date of birth";
      } else {
        setDOBValidate(false);
      }
      if (values.nationality == "") {
        setnationalityValidate(true);
        errors.nationality = "Nationality is required !";
      } else {
        setnationalityValidate(false);
      }
      if (!values.residential) {
        errors.residential = "Address is a required !";
        setaddressValidate(true);
      } else if (values.residential?.length < 10) {
        errors.residential = "Minimum 10 Characters only allowed ";
        setaddressValidate(true);
      } else if (values.residential?.length > 250) {
        errors.residential = "Maximum 250 Characters only allowed ";
        setaddressValidate(true);
      } else {
        setaddressValidate(false);
      }
    }

    if (levelScndstartref.current == true) {
      if (values.verfiType == "") {
        errors.verfiType = "Please select any one of the document type !";
        setdocumentTypeValidate(true);
      } else {
        setdocumentTypeValidate(false);
      }
    }

    setvalidationnErr(errors);

    return errors;
  };

  const formSubmit = async (payload) => {
    formValue["selfieDoc"] = SelfieProofref.current;
    if (SelfieProofref.current != "") {
      setSelfieError(false);
      var data = {
        apiUrl: apiService.kycUpload,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // setFormValue(initialFormValue);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    } else {
      setSelfieError(true);
    }
  };

  const Firstsubmit = async () => {
    let errros = validate(formValue);
    if (
      fullNameValidateref.current === false &&
      DOBValidateref.current === false &&
      nationalityValidateref.current === false &&
      addressValidateref.current === false
    ) {
      setLevelFirst(false);
      setLevelScndstart(true);
    }
  };

  const secondFirstsubmit = async () => {
    let errros = validate(formValue);
    console.log(errros, "errros---");
    console.log(formValue, "=-=-=-=formValue=-=-=-");
    if (documentTypeValidateref.current == false) {
      console.log(formValue, "=-=-=-=formValue=-=-=-");
      setLevelScndstart(false);
      // setLevelScndend(true);
      setLevelThird(true);
    }
  };

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "webp" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        type == "DocumentFront"
          ? setidproofLoad(true)
          : type == "DocumentBack"
          ? setaddressProofLoad(true)
          : setSelfieProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            console.log(type, "type");
            if (type == "DocumentFront") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }
              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "DocumentBack") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
            }
            if (type == "selfiePhoto") {
              setSelfieProofLoad(false);
              setvalidSelfieProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidSelfieProof(1);
              }
              setSelfieproofname(val.name);
              setSelfieProof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const thirdSubmit = async () => {
    console.log(formValue, "=-=-=-form------value=-=-");
    formValue["frontDoc"] = idproofref.current;
    formValue["backDoc"] = addressProofref.current;
    if (idproofref.current != "" && addressProofref.current != "") {
      console.log(formValue, "=-=-=-formvalue entryyyyy=-=-");
      setUploadError(false);
      setLevelThird(false);
      setLevelFourth(true);
    } else {
      console.log("yeah its come");
      setUploadError(true);
    }
  };

  useEffect(() => {
    getSitedata();
    Kycdata();
  }, []);

  const Kycdata = async () => {
    var data = {
      apiUrl: apiService.getKYC,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      if (resp.status != "") {
        var kycData = resp.datas.userDetails;
        var kycDataReject = resp.datas.kycDetails;
        setgetKYCData(kycData);
        setgetKYCDataReject(kycDataReject);
      }
    }
  };

  const getSitedata = async () => {
    try {
      var data = {
        apiUrl: apiService.getSitedata,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status == true) {
        setSiteData(resp.data);
        setSiteStatus(resp.data.siteStatus);
        setkycStatus(resp.data.kycStatus)
      }
    } catch (error) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <Side_bar />
              </div>

              <div className="col-lg-10">
                <section className="asset_section">
                  {kycStatus == "Active" ? (
                    <>
                                      <div className="row">
                    <div className="identification_title">Identification</div>
                    <div className="col-lg-7">
                      {getKYCData.kycstatus == 3 ? (
                        <div className="reject-box">
                          <span>
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          </span>
                          <span>Rejected reason</span>

                          <p className="reject-reason mt-2">
                            {" "}
                            {getKYCDataReject.rejectReson}{" "}
                          </p>
                        </div>
                      ) : getKYCData.kycstatus == 2 ? (
                        <div className="pending-box">
                          <span>
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          </span>
                          <span>
                            Your KYC verification is currently pending. Please
                            wait for the admin to review your details. Thank you
                            for your patience.
                          </span>

                          {/* <p className="reject-reason mt-2">
                            {" "}
                            {getKYCDataReject.rejectReson}{" "}
                          </p> */}
                        </div>
                      ) : (
                        ""
                      )}

                      {getKYCData.kycstatus == 1 ?
                 
                     <>
                    
                      <div className="standard_verify_box">
                        <div className="standard_verify_content id-content">
                          <h3>
                          Your account has been successfully verified.                    
                          </h3>
                          <p className="kyc-cont">
                          Your account has been verified, granting you access to all platform features and the ability to perform transactions. Enjoy additional benefits as well!
                          </p>
                        </div>
                        <div className="standard_verify_img verify-img-cont">
                          <img
                            src={require("../assets/success.webp")}
                          />
                        </div>
                      </div>
                       <div className="individual_kyc_box">
                       <div className="identification_features">
                         <div className="individual_title mb-3">
                         {/* {getKYCData.kycstatus == 1 ? "You're now able to access all features starting today." : "Unlock Exculsive Perks After Verification"} */}
                           
                         </div>

                         <div className="features-box">
                           <h3 className="feature-title">Trading</h3>
                           <p className="text-green">
                             <i class="ri-check-line"></i>
                           </p>
                         </div>
                         <div className="features-box">
                           <h3 className="feature-title">Withdrawals</h3>
                           <p className="text-green">
                             <i class="ri-check-line"></i>
                           </p>
                         </div>
                         <div className="features-box">
                           <h3 className="feature-title">Crypto Deposit</h3>
                           <p className="text-green">
                             <i class="ri-check-line"></i>
                           </p>
                         </div>
                         <div className="features-box">
                           <h3 className="feature-title">Fiat Deposit</h3>
                           <p className="text-green">
                             <i class="ri-check-line"></i>
                           </p>
                         </div>
                         <div className="features-box">
                           <h3 className="feature-title">P2P trading</h3>
                           <p className="text-green">
                             <i class="ri-check-line"></i>
                           </p>
                         </div>

                         <div className="my-4">
                           {getKYCData.kycstatus == 2 ? (
                             <button
                               disabled
                               style={{ backgroundColor: "grey" }}
                               className="action_btn opt-nowrap w-100 di"
                               type="button"
                             >
                               Pending
                             </button>
                           ) : getKYCData.kycstatus == 1 ? (
                             <Link to="/deposit">
                               <button
                                 className="action_btn opt-nowrap w-100"
                                 type="button"
                               >
                                 Deposit
                               </button>
                             </Link>
                           ) : (
                             <button
                               className="action_btn opt-nowrap w-100"
                               type="button"
                               data-bs-toggle="modal"
                               data-bs-target="#exampleModal"
                             >
                               Verify Now
                             </button>
                           )}
                         </div>
                       </div>
                     </div>
                     </>
                        : 
                        <>
                        <div className="standard_verify_box">
                        <div className="standard_verify_content id-content">
                          <h3>
                          Complete Your Identity Verification to Unlock Full Access                           
                          </h3>
                          <p className="kyc-cont">
                            To ensure your account is secure and compliant with
                            local laws, please complete a quick identity
                            verification. This will allow you to access all our
                            services seamlessly.
                          </p>
                        </div>
                        <div className="standard_verify_img verify-img-cont">
                          <img
                            src={require("../assets/refer_earn_banner.webp")}
                          />
                        </div>
                      </div>
                             <div className="individual_kyc_box">
                             <div className="identification_features">
                               <div className="individual_title mb-3">
                               {/* {getKYCData.kycstatus == 1 ? "You're now able to access all features starting today." : "Unlock Exculsive Perks After Verification"} */}
                                 
                               </div>
      
                               <div className="features-box">
                                 <h3 className="feature-title">Trading</h3>
                                 <p className="text-yellgreenow">
                                   <i class="ri-check-line"></i>
                                 </p>
                               </div>
                               <div className="features-box">
                                 <h3 className="feature-title">Withdrawals</h3>
                                 <p className="text-yellow">
                                   <i class="ri-check-line"></i>
                                 </p>
                               </div>
                               <div className="features-box">
                                 <h3 className="feature-title">Crypto Deposit</h3>
                                 <p className="text-yellow">
                                   <i class="ri-check-line"></i>
                                 </p>
                               </div>
                               <div className="features-box">
                                 <h3 className="feature-title">Fiat Deposit</h3>
                                 <p className="text-yellow">
                                   <i class="ri-check-line"></i>
                                 </p>
                               </div>
                               <div className="features-box">
                                 <h3 className="feature-title">P2P trading</h3>
                                 <p className="text-yellow">
                                   <i class="ri-check-line"></i>
                                 </p>
                               </div>
      
                               <div className="my-4">
                                 {getKYCData.kycstatus == 2 ? (
                                   <button
                                     disabled
                                     style={{ backgroundColor: "grey" }}
                                     className="action_btn opt-nowrap w-100 di"
                                     type="button"
                                   >
                                     Pending
                                   </button>
                                 ) : getKYCData.kycstatus == 1 ? (
                                   <Link to="/deposit">
                                     <button
                                       className="action_btn opt-nowrap w-100"
                                       type="button"
                                     >
                                       Deposit
                                     </button>
                                   </Link>
                                 ) : (
                                   <button
                                     className="action_btn opt-nowrap w-100"
                                     type="button"
                                     data-bs-toggle="modal"
                                     data-bs-target="#exampleModal"
                                   >
                                     Verify Now
                                   </button>
                                 )}
                               </div>
                             </div>
                           </div>
                        </>
                   
                        }

                     
                    </div>

                    {/* FAQ */}
                    <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div
                            class="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="faq mt-5">
                              <h5 className="faq-title">FAQ</h5>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingOne"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    1. Why is KYC verification required?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    KYC verification is essential to comply with
                                    regulations and ensure the security of our
                                    platform by preventing fraud and other
                                    illegal activities.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingTwo"
                                >
                                  <button
                                    class="accordion-button collapsed max-wrap"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    2. What documents are needed for KYC
                                    verification?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingTwo"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    You will need to submit a government-issued
                                    ID (like a passport or driver's license) and
                                    a recent proof of address (such as a utility
                                    bill or bank statement).
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingThree"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    3. How long does the KYC process take?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingThree"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    The verification process typically takes [X
                                    hours/days], depending on the volume of
                                    requests and the clarity of the documents
                                    provided.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFour"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    4. Is my personal information safe?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFour"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    Yes, we use encryption and follow strict
                                    data protection regulations to ensure that
                                    your personal information remains secure.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFive"
                                >
                                  <button
                                    class="accordion-button collapsed max-wrap"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    5. What should I do if my KYC verification
                                    is rejected?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFive"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    If your verification is rejected, you will
                                    receive an email with the reasons and
                                    instructions on how to resolve the issue.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </>
                  ) : (
                    <div className="row">
                    <div className="identification_title">Identification</div>
                    <div className="col-lg-7">
                    <div className="deposit mt-5 h-100">
                          <div className="dep-kyc">
                            <div className="dep-kyc-head">
                              <img
                                src={WARNICON}
                                alt="warn-icon"
                                className="deposit-imp-icon"
                              />
                              <h6>Identification Temporarily Unavailable</h6>
                            </div>
                            {/* <p>
                            Due to ongoing platform maintenance, withdrawals are
                            currently restricted. We apologize for any
                            inconvenience this may cause. Our team is working
                            diligently to restore full service as soon as
                            possible.
                          </p> */}
                            <p>{siteData.kycMaintenance}</p>
                            <p className="my-3">
                              {/* {withdrawContent} */}
                              {/* <span className="text-yellow">00:00:00</span> */}
                            </p>
                            <div>
                              <img
                                src={require("../assets/kyc-unavail.png")}
                                alt="Verify kyc"
                                className="before_kyc_depo withdraw-p-l-24"
                              />
                            </div>
                            <p className="mt-4">
                              Thank you for your patience and understanding.
                            </p>
                            <div className="withdraw-p-l-24">
                              <Link to="/dashboard">
                                <button className="action_btn w-100 mb-2">
                                  Back To Home
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                    </div>

                    {/* FAQ */}
                    <div className="col-lg-5">
                      <div>
                        <div className="container">
                          <div
                            class="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="faq mt-5">
                              <h5 className="faq-title">FAQ</h5>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingOne"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    1. Why is KYC verification required?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    KYC verification is essential to comply with
                                    regulations and ensure the security of our
                                    platform by preventing fraud and other
                                    illegal activities.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingTwo"
                                >
                                  <button
                                    class="accordion-button collapsed max-wrap"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    2. What documents are needed for KYC
                                    verification?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingTwo"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    You will need to submit a government-issued
                                    ID (like a passport or driver's license) and
                                    a recent proof of address (such as a utility
                                    bill or bank statement).
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingThree"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    3. How long does the KYC process take?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingThree"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    The verification process typically takes [X
                                    hours/days], depending on the volume of
                                    requests and the clarity of the documents
                                    provided.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFour"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    4. Is my personal information safe?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFour"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    Yes, we use encryption and follow strict
                                    data protection regulations to ensure that
                                    your personal information remains secure.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFive"
                                >
                                  <button
                                    class="accordion-button collapsed max-wrap"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    5. What should I do if my KYC verification
                                    is rejected?
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFive"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    If your verification is rejected, you will
                                    receive an email with the reasons and
                                    instructions on how to resolve the issue.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                </section>

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  {levelFirst && (
                    <div class="modal-dialog modal-dialog-centered modal-md">
                      <div class="modal-content">
                        <div class="modal-header lvl-one-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Level - 1{" "}
                            <span className="mar-lft-2">Verification </span>
                          </h1>
                          <button
                            type="button"
                            class="btn-close btn-close-custom"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div className="modal-not-header">
                          <div className="modal-notify-content">
                            To complete level one verification, Please provide
                            your basic information. Thank you!
                          </div>
                        </div>

                        <div className="modal-body personal_verify_body lvl-one-body">
                          <div className="mar-top-12">
                            <div className="first_name">
                              <h4 className="select_id_text">Full Name</h4>
                              <input
                                type="text"
                                placeholder="Enter your full name"
                                className="w-100"
                                name="fullname"
                                value={fullname}
                                onChange={handleChange}
                              />
                            </div>
                            {validationnErr && validationnErr.fullname && (
                              <p className="errorcss">
                                {validationnErr.fullname}
                              </p>
                            )}
                          </div>
                          <div className="id_number">
                            <div className="first_name">
                              <h4 className="select_id_text">DOB</h4>
                              <input
                                type="date"
                                name="dob"
                                max={today}
                                value={dob}
                                onChange={handleChange}
                              />
                            </div>
                            {validationnErr && validationnErr.dob && (
                              <p className="errorcss">{validationnErr.dob}</p>
                            )}
                          </div>
                          <div className="id_number">
                            <div className="first_name">
                              <h4 className="select_id_text">Nationality</h4>
                              <Select
                                options={options}
                                value={options.find(
                                  (option) => option.value === nationality
                                )}
                                onChange={handleNationalityChange}
                                placeholder="Select nationality"
                                className="kyc_nation"
                                styles={colourStyles}
                              />
                            </div>
                            {validationnErr && validationnErr.nationality && (
                              <p className="errorcss">
                                {validationnErr.nationality}
                              </p>
                            )}
                          </div>
                          <div className="id_number">
                            <div className="first_name">
                              <h4 className="select_id_text">
                                Residential Address
                              </h4>
                              <textarea
                                maxLength="250"
                                name="residential"
                                value={residential}
                                onChange={handleTextChange}
                                placeholder="Enter the address"
                                fluid
                                rows="3"
                                className="kyc_address"
                              />
                            </div>
                            {validationnErr && validationnErr.residential && (
                              <p className="errorcss">
                                {validationnErr.residential}
                              </p>
                            )}
                          </div>
                        </div>

                        <div class="modal-footer lvl-one-body">
                          <button
                            className="modal_continue_btn"
                            onClick={Firstsubmit}
                          >
                            Next
                          </button>
                          <p className="modal_footer_text mar-top-15">
                            This information is used for identity verification
                            only, and
                            <br /> will be kept secure by VOLTRIX
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {levelScndstart && (
                    <div class="modal-dialog modal-dialog-centered modal-md">
                      <div class="modal-content">
                        <div class="modal-header lvl-one-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Level - 2{" "}
                            <span className="mar-lft-2">Verification</span>
                          </h1>
                          <button
                            type="button"
                            class="btn-close btn-close-custom"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-not-header">
                          <div className="modal-notify-content">
                            Please select a document for verification to
                            continue the process. Make sure to prepare the
                            selected document for upload.
                          </div>
                        </div>
                        <div className="modal-body lvl-one-body">
                          {/* <div className="country-content">
                                      <h4>Country/Region of Residence</h4>
                                      <PhoneInput
                                        country={"us"}
                                        value={mobile}
                                        onChange={handlePhoneChange}
                                        inputStyle={{ width: "100%" }}
                                      />
                                    </div> */}
                          <h4 className="select_id_text">Select ID Type</h4>
                          <div className="seleted_id_type">
                            <div className="passport">
                              <img
                                src={require("../assets/passport_icon.png")}
                              />
                              <p>Passport</p>
                            </div>

                            <div class="checkbox-container">
                              <input
                                id="custom-passport"
                                className="input-field regular_checkbox"
                                type="radio"
                                name="id-type"
                                value="Passport" // Set the value to 'Passport'
                                onChange={handleDocumentTypeChange}
                              />
                              <label htmlFor="custom-passport"></label>
                            </div>
                          </div>
                          <div className="seleted_id_type">
                            <div className="passport">
                              <img
                                src={require("../assets/passport_icon.png")}
                              />
                              <p>Aadhaar Card</p>
                            </div>
                            {/* <div className="check_circle">
                                        <i class="ri-checkbox-circle-fill"></i>
                                      </div> */}
                            <div class="checkbox-container">
                              <input
                                id="custom-aadhar"
                                className="input-field regular_checkbox"
                                type="radio"
                                name="id-type"
                                value="Aadhaar Card" // Set the value to 'Aadhaar Card'
                                onChange={handleDocumentTypeChange}
                              />
                              <label htmlFor="custom-aadhar"></label>
                            </div>
                          </div>
                          <div className="seleted_id_type">
                            <div className="passport">
                              <img
                                src={require("../assets/passport_icon.png")}
                              />
                              <p>Driving License</p>
                            </div>
                            {/* <div className="check_circle">
                                        <i class="ri-checkbox-circle-fill"></i>
                                      </div> */}
                            <div class="checkbox-container">
                              <input
                                id="custom-license"
                                className="input-field regular_checkbox"
                                type="radio"
                                name="id-type"
                                value="Driving License" // Set the value to 'Driving License'
                                onChange={handleDocumentTypeChange}
                              />
                              <label htmlFor="custom-license"></label>
                            </div>
                          </div>
                          {documentTypeValidateref.current == true ? (
                            <p className="errorcss">
                              {validationnErr.verfiType}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="modal-footer lvl-one-body">
                          <button
                            className="modal_continue_btn"
                            onClick={secondFirstsubmit}
                          >
                            Next
                          </button>
                          <p className="modal_footer_text mar-top-15">
                            This information is used for identity verification
                            only, and
                            <br /> will be kept secure by VOLTRIX
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {levelThird && (
                    <div class="modal-dialog modal-dialog-centered modal-md">
                      <div class="modal-content">
                        <div class="modal-header lvl-one-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Level - 3{" "}
                            <span className="mar-lft-2">Verification</span>
                          </h1>
                          <button
                            type="button"
                            class="btn-close btn-close-custom"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-not-header">
                          <div className="modal-note-content">
                            Note: Only PNG, JPEG, and WEBP formats are
                            supported. Each file should not exceed 1MB in size.
                          </div>
                        </div>

                        <div className="modal-body lvl-one-body">
                          <div className="mar-top-9">
                            <div className="first_name">
                              <h4 className="select_id_text">Front</h4>
                              <div className="upload-container">
                                {idproofLoad == false ? (
                                  valididproof == 0 ? (
                                    <>
                                      <div className="inner_frst_display">
                                        <i class="fa-solid fa-cloud-arrow-up"></i>
                                        <p>Upload front of your document</p>
                                      </div>
                                    </>
                                  ) : valididproof == 1 ? (
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  ) : (
                                    <img
                                      src={idproofref.current}
                                      className="up_im_past"
                                      alt="National Id Front"
                                    />
                                  )
                                ) : (
                                  <div className="inner_frst_display">
                                    <i class="fa-solid fa-spinner fa-spin fa-sm"></i>
                                  </div>
                                )}

                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload(
                                      "DocumentFront",
                                      e.target.files[0]
                                    )
                                  }
                                />

                                {idproofnameref.current == "" ? (
                                  ""
                                ) : (
                                  <div className="mt-2">
                                    <input
                                      className="proofs_name w-100"
                                      disabled
                                      value={idproofnameref.current}
                                    ></input>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mar-top-9">
                            <div className="first_name">
                              <h4 className="select_id_text">Back</h4>
                              <div className="upload-container">
                                {addressProofLoad == false ? (
                                  validaddressProof == 0 ? (
                                    <>
                                      <div className="inner_frst_display">
                                        <i class="fa-solid fa-cloud-arrow-up"></i>
                                        <p>Upload back of your document</p>
                                      </div>
                                    </>
                                  ) : validaddressProof == 1 ? (
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  ) : (
                                    <img
                                      src={addressProofref.current}
                                      className="up_im_past"
                                      alt="National Id Front"
                                    />
                                  )
                                ) : (
                                  <div className="inner_frst_display">
                                    <i class="fa-solid fa-spinner fa-spin fa-sm"></i>
                                  </div>
                                )}
                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload(
                                      "DocumentBack",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {addressproofnameref.current == "" ? (
                                  ""
                                ) : (
                                  <div className="mt-2">
                                    <input
                                      className="proofs_name w-100"
                                      disabled
                                      value={addressproofnameref.current}
                                    ></input>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {uploadErrorref.current == true ? (
                            <p className="errorcss">
                              Please upload your document images{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="modal-footer lvl-one-body">
                          <button
                            className="modal_continue_btn"
                            onClick={thirdSubmit}
                          >
                            Next
                          </button>
                          <p className="modal_footer_text mar-top-15">
                            This information is used for identity verification
                            only, and
                            <br /> will be kept secure by VOLTRIX
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {levelFourth && (
                    <div class="modal-dialog modal-dialog-centered modal-md">
                      <div class="modal-content">
                        <div class="modal-header lvl-one-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Level - 4{" "}
                            <span className="mar-lft-2">Verification</span>
                          </h1>
                          <button
                            type="button"
                            class="btn-close btn-close-custom"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-not-header">
                          <div className="modal-notify-content">
                            Please upload clear selfie images for the final step
                            of verification. Ensure that the photos are well-lit
                            and clearly show your face for accurate verification
                          </div>
                        </div>
                        <div className="modal-body lvl-one-body">
                          <div className="mar-top-9">
                            <div className="first_name">
                              <h4 className="select_id_text">Take a Selfie</h4>
                              <div className="upload-container">
                                {SelfieProofLoad == false ? (
                                  validSelfieProof == 0 ? (
                                    <>
                                      <div className="inner_frst_display">
                                        <i class="fa-solid fa-cloud-arrow-up"></i>
                                        <p>Upload your photo</p>
                                      </div>
                                    </>
                                  ) : validSelfieProof == 1 ? (
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  ) : (
                                    <img
                                      src={SelfieProofref.current}
                                      className="up_im_past"
                                      alt="National Id Front"
                                    />
                                  )
                                ) : (
                                  <div className="inner_frst_display">
                                    <i class="fa-solid fa-spinner fa-spin fa-sm"></i>
                                  </div>
                                )}

                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload(
                                      "selfiePhoto",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {Selfieproofnameref.current == "" ? (
                                  ""
                                ) : (
                                  <div className="mt-2">
                                    <input
                                      className="proofs_name w-100"
                                      disabled
                                      value={Selfieproofnameref.current}
                                    ></input>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {selfieErrorref.current == true ? (
                            <p className="errorcss">
                              Please upload your selfie image
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="modal-footer lvl-one-body">
                          <button
                            className="modal_continue_btn"
                            onClick={formSubmit}
                          >
                            Submit
                          </button>
                          <p className="modal_footer_text mar-top-15">
                            This information is used for identity verification
                            only, and
                            <br /> will be kept secure by VOLTRIX
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* -- Modal 3 Id and face verify--  */}
                {/* <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        ID verification & Face Verification
                      </h1>
                      <button
                        type="button"
                        class="btn-close btn-close-custom"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <h4 className="select_id_text">
                        Choose an upload method
                      </h4>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/mobile_popup_icon.png")} />
                          <p>Continue on mobile</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/take_photo_icon.png")} />
                          <p>Take photo using webcame</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/upload_file_icon.png")} />
                          <p>Upload file from this device</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button className="modal_continue_btn">Continue</button>
                      <p className="modal_footer_text">
                        Your personal information is encrypted.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
